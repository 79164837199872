import { Link, useLocation, useNavigate } from "react-router-dom"
import "./navButtonsComp.scss"
import { useDispatch } from "react-redux";
import { clearAuthMe } from "../../store/AuthmeSlice/authmeSlice";
import { clearUser } from "../../store/UserSlice/userSlice";

function NavButtonsComp() {

  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();


  const handleGoBack = ()=>{
    navigate(-1);
  }


  //logout function
    const handelLogOut = ()=>{
         // Clear local storage
         dispatch(clearAuthMe())
         dispatch(clearUser());
  
         const serializedState = localStorage.getItem('state');
  
         if (serializedState !== null) {
            localStorage.removeItem('state');
     
          }
         
     
         // Navigate to the login page or another page
         navigate('/login');
    }
  

  return (
    <div className='nav_buttons_container'>
    

    {
      (location.pathname !== "/emp") &&
      (
      <Link to={"/emp"} className='nav_buttons_item' dir='rtl'>
        الصفحة الرئيسية
      </Link>
      )
    } 


    {
      (location.pathname !== "/emp/salarypage") &&
      (
      <Link to={"/emp/salarypage"}  className='nav_buttons_item' dir='rtl'>
        الراتب
      </Link>
      )
    }  


{
      (location.pathname !== "/emp/vacationreq") &&
      (
      <Link to={"/emp/vacationreq"} className='nav_buttons_item' dir='rtl'>
        طلب اجازة
      </Link>
      )
    }  


{
      (location.pathname !== "/emp/eval_emp") &&
      (
      <Link to={"/emp/eval_emp"} className='nav_buttons_item' dir='rtl'>
        التقييم و المتابعة
      </Link>
      )
    }  


{  
   (location.pathname !== "/emp") ?
   (
     <span className='nav_buttons_item nav_buttons_last_item' dir='rtl'  
     onClick={()=>{ handleGoBack(); }}
    >
      عودة الى الخلف
    </span>
   )
   :
   (
    <span className='nav_buttons_item nav_buttons_last_item' dir='rtl'  
    onClick={()=>{ handelLogOut(); }}
   >
     تسجيل الخروج
   </span> 
   )
}

 

</div>
  )
}

export default NavButtonsComp