//function to return the full emp name 
const handleGetFullEmpName = (obj)=>{
    let fullName = "";
    if( obj.family_name && obj.father_name ){
      fullName = obj.name + ' ' + obj.father_name + ' '+ obj.family_name;
    }
    else if( obj.family_name ){
      fullName = obj.name + ' '+ obj.family_name;
    }
    else{
      fullName = obj.name;
    }
  
    return fullName ;
   }


export default handleGetFullEmpName;
  