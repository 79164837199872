import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { fetchAuthMe } from './store/AuthmeSlice/authmeSlice';


// const selectUserIsManager = createSelector(
//     state => state.authme.data,
//     data => data ? data.is_manager : false
//   );


  const IsManagerWrapper = ({children }) => {
    // const isManager = useSelector(selectUserIsManager);

    const dispatch = useDispatch();
 
    const { data, status, error } = useSelector((state) => state.authme);
  
    const token = useSelector(state => state.user.token)
  
    const [loading, setLoading] = useState(false);


  //////

 
  useEffect(() => {

    if(status === 'idle' && token){
      dispatch(fetchAuthMe())
      setLoading(true)
    }
    else if (status === 'loading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  
  }, [status]);

  if (loading) {
    return <div  
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

         }}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
    </div>;
  }

  else if (error || !token ) {

    return <Navigate to="/login" />;
  }



  /////
    
  else if (data?.is_manager) {

      return children;

    }
  
    else{

      // return <>not autherized</>;
      return <Navigate to="/not_authorized" />;
    }

  };
  
  export default IsManagerWrapper;