import React, { useEffect, useState } from 'react'

import './insuranceSectionForEmployee.scss'
import { FaCheck } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice';

function InsuranceSectionForEmployee() {



  const dispatch = useDispatch();


  const authMe = useSelector((state) => state.authme.data);
  
  const authMeStatus = useSelector((state) => state.authme.status);





  const [error,setError] = useState(null)




useEffect(() => {
  if (!authMe) {
    dispatch(fetchAuthMe());
  }
  }, [authMe, dispatch]);
  
  
  
  useEffect(() => {
    if (authMeStatus === 'failed') {
      setError('خطأ في تحميل البيانات');
    }
  }, [authMeStatus]);
  
  

  // useEffect(() => {
  //   const fetchData = async () => {
  //       try {
  //         setLoading(true)
  //         const response = await axios.get(`https://emp.almatin.com/api/V1/insurances/${empInfo?.job_id}`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         });

  //         console.log("the data that coming from the server: ",response.data.data)
  //         setInsuranceData(response.data.data)
  //         setLoading(false)

  //       } catch (error) {
  //         console.error("Error fetching data: ", error);
  //         setInsuranceData(null)
  //         setLoading(false)

  //         if (error.response && error.response.status === 404) {
  //           setError("لا يوجد بيانات لعرضها");
  //         } else {
  //           setError(error.message);
  //         }
          
  //       }
  
  //   };

  //   fetchData();
  // }, []);


  return (
    <div className="EvaluationForEmployeeShowPage_insuranceSection">
    <span className='EvaluationForEmployeeShowPage_insuranceSection_header' dir="rtl">معلومات التأمينات</span>
    <div className='EvaluationForEmployeeShowPage_insuranceSection_items'>
    

       {
        authMeStatus === 'loading'  ? 

        <div className='spinner_getData'>
        <TailSpin
        visible={true}
        height="40"
        width="40"
         color="green"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </div>

        :

        ( authMe?.insurance ? 
        <>
      
        <div className='EvaluationForEmployeeShowPage_insuranceSection_item'>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_valueElement' dir="rtl">
            {
                authMe.insurance?.social_insurance ?    <FaCheck className='IncuranceSection_btn_accept' /> 
                :
                <MdOutlineCancel className='IncuranceSection_btn_cancel' />
            }
        </span>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_keyElement' dir="rtl">التسجيل بالتأمينات الاجتماعية: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_insuranceSection_item'>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_valueElement' dir="rtl">{authMe.insurance?.insurance_salary}</span>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_keyElement' dir="rtl">الراتب التأميني: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_insuranceSection_item'>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_valueElement' dir="rtl">{authMe.insurance?.date_registration}</span>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_keyElement' dir="rtl">تاريخ التسجيل بالتأمينات الاجتماعية: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_insuranceSection_item'>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_valueElement' dir="rtl">{authMe.insurance?.social_insurance_number}</span>
        <span className='EvaluationForEmployeeShowPage_insuranceSection_content_keyElement' dir="rtl">الرقم التأميني: </span>
        </div>
        
        </>
        :
        <>
        {
         error ? 
         <span dir='rtl'>{error}</span>
         :
         <span dir='rtl'>لا يوجد بيانات لعرضها</span>
        }
        </>)

       }


    

    </div>
</div>
  )
}

export default InsuranceSectionForEmployee