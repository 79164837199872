import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './generalPopup.scss'
 
export default function GeneralPopup({reason,firstWord}) {

    // console.log("the first word:",firstWord)
    return (
        <div>
          
            <Popup trigger=
                {  <span className='text_inPopUp' dir='rtl'>{firstWord}</span>}
                position="bottom center"
                //to make it work when hover 
                // on={['hover']} // Set the trigger to hover
                // closeOnDocumentClick
                // mouseEnterDelay={100} // Optional: Adds a slight delay before showing the popup
                // mouseLeaveDelay={100} // Optional: Adds a slight delay before hiding the popup
                >
                <div className='VacationReasonPopup_div_container' dir='rtl'><p dir='rtl'>{reason}</p></div>
            </Popup>
        </div>
    )
};