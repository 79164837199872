// src/AuthmeSlice/authmeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAuthMe = createAsyncThunk('authme/fetchAuthMe', async (_, { getState, rejectWithValue }) => {
  const token = getState().user.token;

  if (!token) {
    // If there is no token, reject the thunk with a custom error message
    return rejectWithValue('No token provided');
  }

  try {
    const response = await axios.post('https://emp.almatin.com/api/v1/auth/me', 
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message || 'Failed to fetch auth me');
  }
});

const authmeSlice = createSlice({
  name: 'authme',
  initialState: {
    data: null,
    status: 'idle',
    error: null
  },
  reducers: {
    clearAuthMe(state) {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthMe.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAuthMe.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAuthMe.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { clearAuthMe } = authmeSlice.actions;
export default authmeSlice.reducer;
