import React, { useEffect, useState } from 'react'

import './punchmentSectionForEmployee.scss'
import PopupGfg from '../../../pages/VacationManagerShow/VacationReasonPopup';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { errorHandeling } from '../../../errorHandeling';
import GeneralPopup from '../../GeneralPopup/GeneralPopup';


function PunchmentSectionForEmplouyee() {

     const token = useSelector(state => state.user.token)


     const [penaltiesArr,setPenaltiesArr] = useState([])

     const [loading,setLoading] = useState(false)
  
   
     const [currentPage, setCurrentPage] = useState(1);
     const [totalPages, setTotalPages] = useState(1);
   
     const [error,setError] = useState(null);


     //Get the begining of the mounth we are in 
     const handleGetBeginingOftheMounth = ()=>{
       
    // Get the current date
     const now = new Date();

     // Get the first day of the current month
     const year = now.getFullYear();
     const month = now.getMonth() + 1; // Months are 0-based, so add 1
     const day = 1;

    // Format the date as YYYY-MM-DD
     const startingDateField = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
     
     console.log("the day for the begaining search for penalty is :",startingDateField);

     return startingDateField;
    
    }

     //function to fetch the penalties for the user
     const fetchPenalty = async (page) => {
      
      let data = {
        date_from: `${handleGetBeginingOftheMounth()}`,
    
        // date_to : `${endingDateVacField} 23:59:59` ,

      };
  

      try {
        setLoading(true);
        const response = await axios.post(
          `https://emp.almatin.com/api/V1/penalties/me?page=${page}`,
           data , 
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        setPenaltiesArr(response.data.data.data);
        setCurrentPage(response.data.data.current_page);
        setTotalPages(response.data.data.last_page);
        setLoading(false);
      } catch (error) {
        setError( errorHandeling(error) );
        setLoading(false);
      }
    };


    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      }
    };
    
    
    
    const renderPageNumbers = () => {
      const pageNumbers = [];
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);
    
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
          >
            {i}
          </button>
        );
      }
    
      return pageNumbers;
    };
    
    
    
    
    
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };


    const handleTypeOfPenalty = ( typeOfPenalty )=>{
     
    if( typeOfPenalty === "FIXED" )
      return "ثابت";

    else if( typeOfPenalty === "PERCENTAGE" )
      return "مئوية";
    
  }
  

     const TruncatedText = (text, maxLength ) => {
      const truncatedText = text.length > maxLength? `${text.split(' ')[0]}...` : text;
    
      return <>{truncatedText}</>
    };


    
    useEffect(() => {
     
      fetchPenalty(1);
     }, []);
    
    
       
         return (

            <div className="EvaluationForEmployeeShowPage_PunchmentSection">
           <span className='EvaluationForEmployeeShowPage_PunchmentSection_header' dir="rtl">معلومات العقوبة</span>

           {
            loading ?
               <span className='forLaterWork' style={{textAlign:"center", padding:"1rem",backgroundColor:"white"}} dir='rtl' >يتم التحميل ...</span>
             :
             <>

             {
              (error) ?
              (<span dir='rtl' style={{color:"red",padding:".5rem"}} >{error}</span>)
              :
              (
                  ( penaltiesArr.length === 0 ) ? 
              
                 (<span className='EvaluationForEmployeeShowPage_PunchmentSection_noPunchment' dir="rtl">لا يوجد عقوبات</span>)               
                    :
             
                (<>
                   <table className='punshmentForEmpShow_table'>
                  <thead>
                  <tr className='punshmentForEmpShow_header_row_table'>
                    <th>تاريخ العقوبة</th>
                    <th>سبب الخصم</th>
                    <th>مقدار الخصم</th>
                    <th>نمط الخصم</th>
                  </tr>
                  </thead>
            
              <tbody>
                    { penaltiesArr.map(
                      item =>(
                        <tr  key={item.id}>
                     <td dir="rtl">{formatDate(item.created_at)}</td>
                    <td dir="rtl">
                      {
                        item.reason ?
                        <PopupGfg reason={item.reason} firstWord = {TruncatedText(item.reason,15)}/>
                        :
                        ""
                      }
                    </td> 
                      
                    <td dir="rtl">{
                     item.type === "PERCENTAGE"
                     ?
                     <>{Math.floor(item.value)}%</>
                     :
                     <>{Math.floor(item.value)}</>
                    }
                    </td>
                     <td dir="rtl">{ handleTypeOfPenalty(item.type) }</td>
                  </tr>
    
                      )
                    )}
                  </tbody>
    
              </table>
           
                  </>) 
    
              )
             }
             


             </>
           }


       </div>
         )
  
}

export default PunchmentSectionForEmplouyee