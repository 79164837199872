import React from 'react'
import Modal from 'react-modal';

import './modalCompMainPage.scss'


function ModalCompMainPage({isOpen,closeFun,data}) {

  console.log("the data for the modal is:",data);


//====Event handler start here====

    const handelVacatinTypeShow = (vacaType)=>{
        if(vacaType === "DEFAULT"){
            return "عادية"
        }
      
        else if(vacaType === "DEATH"){
           return "اجازة وفاة"
        }
        else if(vacaType === "MARRIAGE"){
             return "اجازة زواج"
          }
        else if(vacaType === "MISSION"){
               return "مهمة"
            }
        else if(vacaType === "EXIT_PERMISSION"){
                 return "اذن حروج"
              }
      
      }

    //format the date and get the hours and minutes 
  const getTheHoursAndMinutes = (vacaDate)=>{
    const dateObj = new Date(vacaDate);

    const hours = dateObj.getUTCHours();       // 15
    const minutes = dateObj.getUTCMinutes();   // 20  

    return `${hours}:${minutes}`;
  }


    // to format the date 
   const formatDate = (theDate) => {
    const date = new Date(theDate);
    // Get the day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();
    
    // Return the formatted date
    return `${year}/${month}/${day}`;
  };

        //show the vacation endtime
  const vacatoinTimeShow = (obj,timeType)=>{
    if(timeType === "end_time"){
  
    if(obj.type === "EXIT_PERMISSION" ){
      return (
       <div style={{display:"flex",flexDirection:"row",gap:".5rem"}}>
         <span>{obj.start_date && formatDate(obj.start_date)}</span>
         <span>({obj.start_date && getTheHoursAndMinutes(obj.start_date)})</span>
       </div>
      );
    }
    else if(obj.type === "MISSION"){
     return (
       <div style={{display:"flex",flexDirection:"row",gap:".5rem"}}>
         <span>{obj.end_date && formatDate(obj.end_date)}</span>
         <span>({obj.end_date && getTheHoursAndMinutes(obj.end_date)})</span>
       </div>
      );
    }
    else{
     if(obj.end_date){
      return formatDate(obj.end_date);
    }
    else{
      return "";
    }
    }
   }
   else if(timeType === "start_time"){
  
     if(obj.type === "EXIT_PERMISSION" ){
       return (
        <div style={{display:"flex",flexDirection:"row",gap:".5rem"}}>
          <span>{obj.start_date && formatDate(obj.start_date)}</span>
          <span>({obj.start_date && getTheHoursAndMinutes(obj.start_date)})</span>
        </div>
       );
     }
     else if(obj.type === "MISSION"){
      return (
        <div style={{display:"flex",flexDirection:"row",gap:".5rem"}}>
          <span>{obj.start_date && formatDate(obj.start_date)}</span>
          <span>({obj.start_date && getTheHoursAndMinutes(obj.start_date)})</span>
        </div>
       );
     }
     else{
      if(obj.start_date){
        return formatDate(obj.start_date);
      }
      else{
        return "";
      }

     }
    }
   }


    //check the status of the vacation
    const vacationStatusCheck = (vacaStatus)=>{
        if( vacaStatus === "WAITING_FOR_MANAGER_ACCEPT" )
            return "بانتظار موافقة مدير القسم";
        else if( vacaStatus === "WAITING_FOR_HR_ACCEPT" )
            return "بانتظار موافقة الموارد البشرية";
        else if( vacaStatus === "WAITING_FOR_DOOR_OFFICER_ACCEPT" )
          return "بانتظار موافقة الحرس";  
        else if( vacaStatus === "ACCEPTED" )
            return "تم الموافقة عليها";
        else if( vacaStatus === "DENIED_FROM_MANAGER" )
          return "تم رفضها من قبل المدير";
        else if( vacaStatus === "DENIED_FROM_HR" )
          return "تم رفضها من قبل الموارد البشرية";
        else if( vacaStatus === "DENIED_FROM_DOOR_OFFICER" )
          return "تم رفضها من موظف الباب رئيسي";    
    
      }
     

//====Event handler end here====


  return (
<div>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={closeFun}
        className="ModalCompMainPage_dev"
      >
        <div className='ModalCompMainPage_items'>
        <div className='modal_item' >
        <span className='modal_item_text modal_item_label' dir='rtl'>نوع الاجازة:</span>
        <span className='modal_item_text' dir='rtl'>{data.type && handelVacatinTypeShow(data.type)}</span>
        </div>

        <div className='modal_item' >
        <span className='modal_item_text modal_item_label' dir='rtl'>تبدأ في:</span>
        <span className='modal_item_text' dir='rtl'>{vacatoinTimeShow(data,"start_time")}</span>
        </div>

        <div className='modal_item' >
        <span className='modal_item_text modal_item_label' dir='rtl'>تنتهي في:</span>
        <span className='modal_item_text' dir='rtl'>{vacatoinTimeShow(data,"end_time")}</span>
        </div>

        <div className='modal_item' >
        <span className='modal_item_text modal_item_label' dir='rtl'>السبب:</span>
        <span className='modal_item_text' dir='rtl'>{data.reasons && data.reasons}</span>
        </div>

        <div className='modal_item' >
        <span className='modal_item_text modal_item_label' dir='rtl'>الحالة:</span>
        <span className='modal_item_text' dir='rtl'>{data.status && vacationStatusCheck(data.status)}</span>
        </div>

        <div className='btn_cover_ModalElment'>
         <button onClick={()=>{closeFun()}} className='btn_forMoal'>اغلاق</button>
         </div>
        </div>



      </Modal>
    </div>
  )
}

export default ModalCompMainPage