
import './showManagerVacationsComp.scss'

import { FaCheck } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import PopupGfg from './VacationReasonPopup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import ModalCompManagerVacations from '../ModalCompManagerVacations/ModalCompManagerVacations';
import { useNavigate } from 'react-router-dom';
import { errorHandeling } from '../../../errorHandeling';





function ShowManagerVacationsComp({fetchVacations,vacationsArr,showMore}) {

  const navigate = useNavigate();


const token = useSelector(state => state.user.token)

// const userPermissions = useSelector(selectUserPermissions);


const authMe = useSelector(state => state.authme.data);


const [loadingChanges,setLoadingChanges] = useState(false)

const [loadingElementId,setLoadingElementId] = useState(-1)


const [error,setError] = useState(null)

  
    //to try to make a modal
    const [modalIsOpen, setIsOpen] = useState(false);

    const [modalData,setModalData] = useState({})

    
  function openModal(obj) {
    setModalData(obj)
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


const TruncatedText = (text, maxLength ) => {
  const truncatedText = text.length > maxLength? `${text.split(' ')[0]}...` : text;

  return <>{truncatedText}</>
};





const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


  //get the time and the date for the exist permission 
  const getTimeAndDateFromExistPer = (existDate) =>{

    // Parse the existDate into a Date object
    const parsedDate = new Date(existDate);
    
    // Extract the date part (yyyy-mm-dd)
    const datePart = parsedDate.toISOString().split('T')[0]; // "2024-09-28"
    
    // Extract the time part (hh:mm:ss)
    const timePart = parsedDate.toISOString().split('T')[1].split('.')[0]; // "21:00:00"
    
    return {
      datePart,
      timePart
    }
    
      }
  




let handelAcceptVacation = async(vacationId,empJobId)=>{

  setLoadingElementId(vacationId);

  if(authMe?.is_manager ){
 

 const data = {
  status : 1,
  job_id : empJobId,

 }

 console.log("the data is :",data)
  try {
      setLoadingChanges(true)
      const response = await axios.put(`https://emp.almatin.com/api/V1/vacations/${vacationId}/change-status`,data, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      // const { DepName, DepManger, DepParent } = response.data.data;
      // setDepName(DepName);
      // setDepManger(DepManger);
      // setDepParent(DepParent);
 
      console.log("the dep after fetching is:",response.data)
      setLoadingChanges(false)
      fetchVacations();
      
  } catch (error) {
      console.error("Error fetching department data:", error);
      setLoadingChanges(false)
      setError(errorHandeling(error))
  }


}


}



let handelRefuceVacation = async(vacationId,empJobId)=>{

  setLoadingElementId(vacationId);
 
  if(authMe?.is_manager ){
  const data = {
   status : 0,
   job_id : empJobId,

  }
 
  console.log("the data is :",data)
   try {
       setLoadingChanges(true)
       const response = await axios.put(`https://emp.almatin.com/api/V1/vacations/${vacationId}/change-status`,data, {
           headers: {
               Authorization: `Bearer ${token}`
           }
       });

       setLoadingChanges(false)
       fetchVacations();
       
   } catch (error) {
       console.error("Error fetching department data:", error);
       setLoadingChanges(false)
       setError(errorHandeling(error))
   }
 
 
 }

}



const typeOfVacFun = (type)=>{
  if(type === "DEFAULT")
    return "عادية"
  else if(type === "MARRIAGE")
    return "زواج"
  else if(type === "DEATH")
    return "وفاة"
  else if(type === "EXIT_PERMISSION")
    return "اذن خروج"
  else if(type === "MISSION")
    return "مهمة"
  else 
    return "عادية"
}


  //format the date and get the hours and minutes 
  const getTheHoursAndMinutes = (vacaDate)=>{
    const dateObj = new Date(vacaDate);

    const hours = dateObj.getUTCHours();       // 15
    const minutes = dateObj.getUTCMinutes();   // 20  

    return `${hours}:${minutes}`;
  }


  //show the vacation endtime and starttime
  const vacatoinTimeShow = (obj,timeType)=>{
   if(timeType === "end_time"){
 
   if(obj.type === "EXIT_PERMISSION" ){
     return (
      <div style={{display:"flex",flexDirection:"column"}}>
        <span>{formatDate(obj.start_date)}</span>
        <span>({getTheHoursAndMinutes(obj.start_date)})</span>
      </div>
     );
   }
   else if(obj.type === "MISSION"){
    return (
      <div style={{display:"flex",flexDirection:"column"}}>
        <span>{formatDate(obj.end_date)}</span>
        <span>({getTheHoursAndMinutes(obj.end_date)})</span>
      </div>
     );
   }
   else{
    return formatDate(obj.end_date);
   }
  }
  else if(timeType === "start_time"){
 
    if(obj.type === "EXIT_PERMISSION" ){
      return (
       <div style={{display:"flex",flexDirection:"column"}}>
         <span>{formatDate(obj.start_date)}</span>
         <span>({getTheHoursAndMinutes(obj.start_date)})</span>
       </div>
      );
    }
    else if(obj.type === "MISSION"){
     return (
       <div style={{display:"flex",flexDirection:"column"}}>
         <span>{formatDate(obj.start_date)}</span>
         <span>({getTheHoursAndMinutes(obj.start_date)})</span>
       </div>
      );
    }
    else{
     return formatDate(obj.start_date);
    }
   }
  }


  //function to return the full emp name 
 const handleGetFullEmpName = (obj)=>{
  let fullName = "";
  if(obj.family_name){
    fullName = obj.name + ' ' + obj.family_name;
  }
  else{
    fullName = obj.name;
  }

  return fullName ;
 }


//handling showing more of vacations for the manager
 const handleShowMoreClicked = ()=>{
  navigate("/emp/vacationmang");
 }

  return (
    <div className='ShowManagerVacationsComp'>


    <div className="vacationManagerShow_content">
        <span className="vacationManagerShow_title" dir="rtl">طلبات الاجازة</span>

        {
          error 
          ?
          <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
          :
          <></>
        }
        
        <table className='vacationManagerShow_table'>
        <thead>
        <tr className='vacationManagerShow_header_row_table'>
          <th>خيارات</th>
          <th>تفاصيل</th>
          <th>تاريخ النهاية</th>
          <th>تاريخ البداية</th>
          <th>الاسم</th>
          <th>الرقم الوظيفي</th>
        </tr>
        </thead>


    <tbody>

          { vacationsArr?.map(
            obj =>(
              <tr  key={obj.id}>
              <td>
            <div className='vacationManagerShow_table_choices'>
            {
             ( loadingChanges  ) &&  (obj.id === loadingElementId) ?
               <div className='spinner_getData'>
               <TailSpin
               visible={true}
               height="20"
               width="20"
               color="rgb(246,229,56)"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
                 />
               </div>
          :
         <>
              <button onClick={()=>{handelRefuceVacation(obj.id,obj.employee?.job_id)}} disabled={loadingChanges }>
              <MdOutlineCancel className='vacationManagerShow_btn_cancel' />
              </button>
        
              <button onClick={()=>{handelAcceptVacation(obj.id,obj.employee?.job_id)}} disabled={loadingChanges }>
              <FaCheck className='vacationManagerShow_btn_accept'   />
              </button>
         
         </>
        }

            </div>
           </td>
           <td dir="rtl">
              <div className='btn_for_VacaMoreDetails_cover'>
                <button className='btn_for_VacaMoreDetails' onClick={()=>{openModal(obj)}}>عرض</button>
              </div>
           </td> 
           <td dir="rtl">{vacatoinTimeShow(obj,"end_time")}</td>
           <td dir="rtl">{vacatoinTimeShow(obj,"start_time")}</td> 
           <td dir="rtl">{obj.employee ? handleGetFullEmpName(obj.employee) : "لم يتم تحديد الاسم بعد"}</td>
           <td dir="rtl">{obj.employee?.job_id}</td>
        </tr>

            )
          )}
        </tbody>

    </table>

    {
      showMore &&
      (<div className='showMore_btn_cover'>
         <button className='showMore_btn' onClick={()=>{handleShowMoreClicked()}} dir='rtl'>عرض المزيد...</button>
       </div>)
    }


    </div>

    <ModalCompManagerVacations isOpen={modalIsOpen} closeFun={closeModal} data={modalData}/>


    </div>
  )
}

export default ShowManagerVacationsComp