import axios from "axios";
import { errorHandeling } from "../../errorHandeling";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseLabelsArr } from "../../libs/arrEvalDirectManager";
import DirectManagerEvalStatPopup from "../DirectManagerEvalStatPopup/DirectManagerEvalStatPopup";

function DirectManagerShowEval() {

    const token = useSelector(state => state.user.token)

    const [empData,setEmpData] = useState(null);
    
    const [loading,setLoading] = useState(false);

    const [error,setError] = useState(null);

    const fetchData = async () => {
        try{
        setLoading(true);
        const response = await axios.get(
            `https://emp.almatin.com/api/V1/manager-evaluations/my-employees-evaluations`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

      setEmpData(response.data.data);

      setLoading(false);

    }
    catch(error){
     setLoading(false);
     setError(errorHandeling(error));
    }
    };

//to sum the degree and return it for the evaluation  
 const handleGetTheDegree = (item)=>{
  let sum = 0 ;
  
  for(let i =0 ; i < 10 ; i++){
    if( item[responseLabelsArr[i]] !== null ){
    sum += Math.round(item[responseLabelsArr[i]]);
    }
    else{
      sum += 0;
    }
  }

  return sum ;
 }


    useEffect(()=>{
        fetchData();
    },[])

  return (
    <div className='EvaluationForEmployeeShowPage_evalSection_item'>
     {
        (error) &&
        (<span style={{color:"red"}}>{error}</span>)
     }   

     {
      (loading) 
      ? 
      (
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">يتم التحميل ...</span>
      )
      :
      (
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">
          {
          (empData?.first_mark)
           ? 
          (<DirectManagerEvalStatPopup firstWord={handleGetTheDegree(empData)} data={empData} />)    
          : 
          ""
          }
        </span>
      )
     }
  
    <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">التقييم من قبل الموظفين: </span>
    </div>
  )
}

export default DirectManagerShowEval