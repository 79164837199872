import { useEffect, useState } from 'react';
import './salaryPage.scss'
import {  useSelector } from 'react-redux';
import axios from 'axios';
import LottieHandler from '../../components/LottieHandler/LottieHandler';
import NavButtonsComp from '../../components/NavButtonsComp/NavButtonsComp';


function SalaryPage() {
    
    const token = useSelector(state => state.user.token)

    const [salaryData , setSalaryData] = useState({})

    const [loading,setLoading] = useState(false)

    const [error,setError] = useState(null);

    useEffect(()=>{

        const fetchAuthmeTemp = async()=>{
            setLoading(true);
            try {
                const response = await axios.post('https://emp.almatin.com/api/v1/auth/me', 
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  }
                );
                setSalaryData(response.data.data);
                console.log('the authme for the salary:',response.data.data)
                if(!response.data.data.salary){
                    setError("*لا يوجد بيانات راتب لعرضها يرجى مراجعة الموارد البشرية")
                }
                setLoading(false);
              } catch (error) {
                setLoading(false);
                setError(error.message);
              }
        }

        fetchAuthmeTemp();
    },[])
    


  const formateTheDate = (longDate)=>{
    // Create a new Date object from the string
   const dateObj = new Date(longDate);

   // Extract the year, month, and day
  let year = dateObj.getFullYear();  // Get full year
  let month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
  let day = dateObj.getDate().toString().padStart(2, '0'); // Get day and pad with 0 if necessary

// Combine into 'yy-mm-dd' format
  return `${year}-${month}-${day}`;
  } 



  return (
    <div className='salaryPage'>
        
        <div className='salaryPage_container'>

        {
          loading ?
            <div style={{width:"100%",display:"flex",alignItems:"cneter",justifyContent:"center",marginBottom:"15rem"}} className='forLoadingSalary'>
                 
              <LottieHandler type="kite" message="يتم التحميل ..." />
                
            </div>
                :
           <>
  
<span className='error_Message' dir='rtl'>{error}</span>

<div className="salaryShow_container">


<span className='salaryShow_container_title'>الصافي</span>
<span className="salaryShow_number" dir="rtl">{salaryData.salary ? salaryData.salary?.net_salary : 0}<span className="salaryShow_number_CountryCurrency" >ل.س</span> </span>
<div className="salaryShow_lastTimeUpdate_container">
    <span >{salaryData.salary ? formateTheDate(salaryData.salary?.date ): "2024-2-12"}</span>
    <span dir="rtl">تاريخ التحديث :</span>
</div>
</div>

<div className='salaryShow_info_container'>

<div className="salaryShow_info_left">

    <span className="salaryShow_info_left_title">معلومات الحسم</span>

    <div className="salaryShow_info_left_items">
   
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.absence_cut : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">حسم الغياب :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.without_salary : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">بلا راتب :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.without_salary_cut : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">حسم بلا راتب :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.late_cut : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">حسم التأخر :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.mobile_bill : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">فاتورة الجوال :</span>  
    </div>
    <div className="salaryShow_info_left_item salaryShow_info_left_item_sanc">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.punishments : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">العقوبات :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.ordinary_advance : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">سلفة عادية :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.jop : 0 }</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">جوب :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.social_insurance : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">تأمينات اجتماعية :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.income_tax : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">ضريبة الدخل :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.cooperat_box : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">صندوق التعاون :</span>  
    </div>
    <div className="salaryShow_info_left_item">
        <span className="salaryShow_info_left_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.severance_pay : 0}</span>
        <span className="salaryShow_info_left_item_title" dir="rtl">راتب الانقطاع عن العمل :</span>  
    </div>

  
    </div>
</div>



<div className='salaryShow_info_right'>
    <span className="salaryShow_info_right_title">معلومات الراتب</span>

    <div className="salaryShow_info_right_items">
   
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary?.job_id}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">الرقم الوظيفي : </span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.base_salary : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">الراتب المقطوع :</span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.motivations : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">الحوافز : </span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.additional : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">المبلغ الاضافي : </span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.food_allowance : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">بدل الطعام :</span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.productivity_motivations : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">الحوافز الإنتاجية :</span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.nature_work : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">طبيعة العمل :</span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.variable_compensation : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">تعويضات متغيرة :</span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.fixed_compensation : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">تعويضات ثابتة :</span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.total_benefit : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">مجموع الاستحقاق :</span>  
    </div>
    <div className="salaryShow_info_right_item">
        <span className="salaryShow_info_right_item_info" dir="rtl">{salaryData.salary ? salaryData.salary?.absence : 0}</span>
        <span className="salaryShow_info_right_item_title" dir="rtl">الغياب :</span>  
    </div>
                   
    </div>
</div>
</div>

    {/*for the navivagtion buttons */}
    <NavButtonsComp/>
               
     </>
     }
        


     </div>
    </div>
  )
}

export default SalaryPage