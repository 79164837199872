import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createSelector } from 'reselect';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null;
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);

const hasAnyPermission = (userPermissions, requiredPermissions) => {
  return requiredPermissions.some(permission => userPermissions.includes(permission));
};

const PermissionWrapper = ({ requiredPermissions, children }) => {
  const userPermissions = useSelector(selectUserPermissions);


  if (!hasAnyPermission(userPermissions, requiredPermissions)) {

    // return <>not autherized</>;
    return <Navigate to="/not_authorized" replace />;
  }

  return children;
};

export default PermissionWrapper;
