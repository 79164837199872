import React, { useState } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/UserSlice/userSlice';
import { useNavigate } from 'react-router-dom';
import { IoEye,IoEyeOff } from "react-icons/io5";
import { LineWave } from "react-loader-spinner";

import almatin_img from '../../img/almatin_group.png';
import './login.scss';



function Login() {


  const dispatch = useDispatch();
  const navigate = useNavigate();


  //===State start here===

  const [jobId, setJobId] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading,setLoading]= useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);


  //===State end here===




//===Event handler start here===


const handleClickShowPassword = () => {
setShowPassword(prev =>(!prev))
};



  const handleLogin = async (e) => {
    e.preventDefault();

    console.log("the button have been clicked")
  
    console.log("the type of the pass:",password)
    console.log("the type of the jobid:",jobId)


    try {
      setLoading(true);
      
      const response = await axios.post(
        'https://emp.almatin.com/api/V1/auth/login',
        {
          job_id: jobId,
          password: password
        },
        {
          headers: {
            'Accept': 'application/json',
          },
          timeout: 10000, // Increase timeout for slow networks
        }
      );
  
      if (response.data) {
        console.log('Login successful:', response.data.data);
        const token = response.data.data.token;
        dispatch(setUser({ token, rememberMe }));
        navigate("/emp");
      }
      
    } catch (error) {

      setLoading(false);

      
      // Handle error
      if (error.response && (error.response.status === 401 || error.response.status === 422 || error.response.status === 404)) {
        setErrorMessage('بيانات الاعتماد غير صحيحة');
      } else if (error.response && error.response.data && error.response.data.message) {
        // Specific error message from the backend
        setErrorMessage(error.response.data.message);
      } else if(error.code && error.code === "ERR_NETWORK") {
        // General error message
          setErrorMessage('هنالك مشكلة في بالانترنيت يرجى المحاولة لاحقا');
      }
        else{
          setErrorMessage('هنالك مشكلة ما يرجى المحاولة لاحقا');
        }

      
      console.error('There was an error!', error);
    }
  }

  const handelChenkbox = ()=>{
    console.log("the check box is clicked the remeber me:", )
    setRememberMe(prev =>(!prev))
  }


//===Event handler end here===




  const isButtonDisabled = !jobId || !password;



  return (
    <form  onSubmit={handleLogin}>
    <div className='login'>


      <div className="login_header">
        <span>تسجيل الدخول</span>
      </div>
      <div className="login_container">
        <img src={almatin_img} alt="an image about al matin" className='img_login'/>

        {
          errorMessage ? 
        <span className='login_page_errorMessage'>{errorMessage}</span>:
        <></>
        }

        <span className='login_notif' dir='rtl'><span className='login_notif_noteStar' dir='rtl'>*ملاحظة :</span>لقد تم تحديث الموقع لذلك يرجى ادخال الرقم الوطني في حقل كلمة المرور و تغير كلمة المرور الخاصة بك بعد ذلك</span>
        
        <div className="login_input_container">
        <div className='login_input'>
          <input type="text"className='input_field_login' dir="rtl"  value={jobId} onChange={(e) => setJobId(e.target.value)} />
          <label htmlFor="">الرقم الوظيفي</label>
        </div>

        <div className='login_input'>
        {
          showPassword ?<IoEyeOff onClick={(e)=>handleClickShowPassword(e)} />
          :<IoEye  onClick={(e)=>handleClickShowPassword(e)} />

        }
        

          <input type={showPassword ? "text" : "password"   }
           className='input_field_login input_field_login_password' dir="rtl"  value={password} onChange={(e) => setPassword(e.target.value)}/>


          <label htmlFor="">كلمة المرور</label>
        </div>

        <div className='login_input_btn_container'>

        {
          loading ? 

        <LineWave
  
  visible={true}
  height="15rem"
  width="15rem"
  color="#4fa94d"
  ariaLabel="line-wave-loading"
  wrapperStyle={{}}
  wrapperClass="live_btn_wave"
  firstLineColor=""
  middleLineColor=""
  lastLineColor=""
  />
        : 
    
        <button className={(isButtonDisabled || loading)?"btn_for_login btn_for_login_disabled":"btn_for_login"} type="submit"  disabled={isButtonDisabled || loading} >تسجيل الدخول</button>
  
          }


        <div className='login_input_checkbox'> 
        <label htmlFor="">تذكرني</label>
        <input type="checkbox" onClick={(e)=>handelChenkbox(e)} />
        </div>

       </div>

        </div>


      </div>
      
      <div className="login_footer">
      <div className='text_footer' dir='rtl'>
      <div className='login_text_footer_name_section' dir='rtl'>
          <span dir='rtl'>تصميم و برمجة م. صالح الحايك و م.علاء الدين عاصي </span>
          <span dir='rtl'>من قسم التقانة لمجموعة المتين</span>
          </div>

          <span><a href="#">Almatin.com</a>&copy; 2024</span>

        </div>
      </div>


    </div>
    </form> 
  )
}

export default Login