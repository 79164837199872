import React from 'react'
import './unautherized.scss'
import { Link } from 'react-router-dom'


function Unautherized() {
  return (
    <div className='Unautherized'>
        <div className='Unautherized_text'>
           <span className='Unautherized_text_big'>401</span>
           <span className='Unautherized_text_small' dir='rtl'> ليس لديك الصلاحية اللازمة لدخول هذه الصفحة<Link className='style_link_unautherizedPage' to={"/emp"}>(عودة للصفحة الرئيسية)</Link></span>

        </div>

    </div>
  )
}

export default Unautherized