import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import axios from 'axios';
import ShowEvalResponsableEmpStartNotif from '../../components/ShowEvalResponsableEmpStartNotif/ShowEvalResponsableEmpStartNotif';
import ShouldEvalMyManager from '../../components/ShouldEvalMyManager/ShouldEvalMyManager';
import SuccessNotifiMainPage from '../../components/SuccessNotifiMainPage/SuccessNotifiMainPage';
import NavButtonsComp from '../../components/NavButtonsComp/NavButtonsComp';
import ModalCompMainPage from "./ModalCompMainPage/ModalCompMainPage"
import ShowManagerVacationsMainPage from '../../components/ShowManagerVacationsMainPage/ShowManagerVacationsMainPage';
import ShowManagerEvalNofication from '../../components/ShowManagerEvalNofication/ShowManagerEvalNofication';

import './main_page.scss';


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function MainPage() {
    
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();
    
    const successMessage = location.state?.successMessage || null;


    const authMe = useSelector((state) => state.authme.data);
    
    const authMeStatus = useSelector((state) => state.authme.status);

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);


    //====States start here====

    const [vacationData , setVacationData] = useState([])

    const [loading,setLoading] = useState(false)

    const [error,setError] = useState(null);

    //to try to make a modal
    const [modalIsOpen, setIsOpen] = useState(false);

    const [modalData,setModalData] = useState({})


    //====States end here====


    //====Event handler start here====

   //check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    return false;
  }
  else{
    return true;
  }
};   


    
  function openModal(obj) {
    setModalData(obj)
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


    const fetchAuthmeTemp = async()=>{
        setLoading(true);
        try {
            const response = await axios.post('https://emp.almatin.com/api/v1/auth/me', 
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
            setVacationData(response.data?.data?.vacations || []);
            console.log('the authme for the vacation:',response.data.data)

            setLoading(false);
          } catch (error) {
            setLoading(false);
            setError(error.message);
          }
    }



    // to format the date 

    const formatDate = (theDate) => {
      const date = new Date(theDate);
      const day = String(date.getUTCDate()).padStart(2, '0'); // Pad day to two digits
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Pad month to two digits
      const year = date.getUTCFullYear();
        
      return `${year}-${month}-${day}`; // Format to yyyy-MM-dd
        };   


  //check the status of the vacation
  const vacationStatusCheck = (vacaStatus)=>{
    if( vacaStatus === "WAITING_FOR_MANAGER_ACCEPT" )
        return "بانتظار موافقة مدير القسم";
    else if( vacaStatus === "WAITING_FOR_HR_ACCEPT" )
        return "بانتظار موافقة الموارد البشرية";
    else if( vacaStatus === "WAITING_FOR_DOOR_OFFICER_ACCEPT" )
      return "بانتظار موافقة الحرس";
    else if( vacaStatus === "ACCEPTED" )
        return "تم الموافقة عليها";
    else if( vacaStatus === "DENIED_FROM_MANAGER" )
      return "تم رفضها من قبل المدير";
    else if( vacaStatus === "DENIED_FROM_HR" )
      return "تم رفضها من قبل الموارد البشرية";
    else if( vacaStatus === "DENIED_FROM_DOOR_OFFICER" )
      return "تم رفضها من موظف الباب الخارجي";  

  }



  const handelVacatinTypeShow = (vacaType)=>{
    if(vacaType === "DEFAULT"){
        return "عادية"
    }
  
    else if(vacaType === "DEATH"){
       return "اجازة وفاة"
    }
    else if(vacaType === "MARRIAGE"){
         return "اجازة زواج"
      }
    else if(vacaType === "MISSION"){
           return "مهمة"
        }
    else if(vacaType === "EXIT_PERMISSION"){
             return "اذن حروج"
          }
  
  }  



  //function to return the full emp name 
  const handleGetFullEmpName = (obj)=>{
    let fullName = "";
    if(obj.family_name){
      fullName = obj.name + ' ' + obj.family_name;
    }
     else{
       fullName = obj.name;
     }
    
     return fullName ;
   }  
  


    //====Event handler end here====


    //====useEffect start here====

    useEffect(()=>{

        fetchAuthmeTemp();
    },[])

  
  useEffect(() => {
    if (!authMe) {
      dispatch(fetchAuthMe());
    }
  }, [authMe, dispatch]);



  useEffect(() => {
    if (authMeStatus === 'failed') {
      setError('خطأ في تحميل البيانات');
    }
    else if(authMeStatus === 'succeeded'){
      if(authMe.change_password){
        navigate("/emp/change_pass");
      }
    }
  }, [authMeStatus]);


  //====useEffect end here==== 



console.log("the data comming from redux",authMe)



  return (
    <div className='main_page_full'>

     {
      (!authMe.change_password)
      ?
      (
        <div className="main_page_full_container">

        {
            error 
            ?
           <span className='error_mainPage' dir='rtl'>{error}</span>
            :
            <></>
        }




   <div className='show_imp_info_notifi'>

    {
      (successMessage) &&
      (
        <div className='show_notifi_container'>
        <SuccessNotifiMainPage successMessage={successMessage}/>
      </div>    
      )
    }
   
    {
      //show the manager or the emp responsable to eval peoble notifi to eval the peoble
      ( authMe?.is_manager || checkPermissionAndThrow("update_evaluations")
        || checkPermissionAndThrow("hr_manager")
      ) && 
      (
        <div className='show_notifi_container'>
        <ShowManagerEvalNofication/>
        </div>  
      )
    }


      <div className='show_notifi_container'>
        <ShouldEvalMyManager/>
      </div>    

     {
      //the notifi to fire the evaluation system show only to the person who has the permissions 
      ( checkPermissionAndThrow("create_evaluations") || checkPermissionAndThrow("create_manager_evaluations") ) 
      &&
      (
        <div className='show_notifi_container'>
        <ShowEvalResponsableEmpStartNotif/>
        </div>  
      )
     }



    {
      (authMe?.is_manager) &&
      (
        <div className='show_manager_emps_vacations'>
        {  
        <ShowManagerVacationsMainPage/>
        }
       </div>
      )
    }
   

    </div>




<div className='checkForTheVacationsStatus_section'>

    {loading ? (
        <>
        <div className='checkForTheVacationsStatus_section_loading'>
        <TailSpin
          visible={true}
          height="26"
          width="26"
          color="rgba(52,144,220,255)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <span dir='rtl' style={{color:"black" , marginRight:".4rem"}}>يتم التحقق من حالة الاجازات...</span>
        </div>
        </>
      )
      :<></>
      }



    {
        vacationData.length > 0 &&
        (
            <table >
            <thead>
            <tr >
            <th>تفاصيل</th>
            <th>حالة الطلب</th>
            <th>الاجازة</th>
           </tr>
           </thead>
          
          
           <tbody >
          
          { vacationData?.map(
              (item,index) =>(
               <tr key={index} >
              <td dir="rtl">
                <div className='btn_for_VacaMoreDetails_cover'>
                <button className='btn_for_VacaMoreDetails' onClick={()=>{openModal(item)}}>اضغط</button>
                </div></td>  
             <td dir="rtl">{item.status && vacationStatusCheck(item.status)}</td>
             <td dir="rtl">{item.type && handelVacatinTypeShow(item.type)}</td>
          </tr>
      
          )
            )}
          
          
          
          </tbody>
          
          </table>
        )
    }


    <ModalCompMainPage isOpen={modalIsOpen} closeFun={closeModal} data={modalData}/>


</div>



      {
       authMeStatus === 'loading'
        ?
      <>
       <div className="loading-spinner">
        <TailSpin
          visible={true}
          height="40"
          width="40"
          color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
        </div>
      </>
      :

      <>
      
      <div className='main_page'>
        <div className='main_page_header'>
            <span>معلومات الموظف</span>
        </div>
        <div className="main_page_content">

            <div className='main_page_right_side'>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.job_id}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >الرقم الوظيفي:</span>
                </div>
            </div>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.name} {authMe?.family_name}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >الاسم: </span>
                </div>
            </div>


            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.address}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >العنوان: </span>
                </div>
            </div>

            
            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span >{authMe?.mobile}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >رقم الموبايل: </span>
                </div>
            </div>



            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir="rtl">{authMe?.department?.title}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >القسم: </span>
                </div>
            </div>

            </div>

            <div className='main_page_left_side'>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.hiring_date && formatDate(authMe?.hiring_date)}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >تاريخ المباشرة: </span>
                </div>
            </div>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.qualification}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >المؤهلات: </span>
                </div>
            </div>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.job_position?.title}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >المنصب الوظيفي:</span>
                </div>
            </div>



            <div className='content_container_main_page last_main_page_element'>
                <div className="left_main_page" >
                <span  dir="rtl">{authMe?.department?.manager ? handleGetFullEmpName(authMe?.department?.manager) : ""}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >رئيس القسم: </span>
                </div>
            </div>

            <div className='content_container_main_page last_main_page_element'>
                <div className="left_main_page" >
                <span  dir="rtl">{authMe?.direct_manager ? handleGetFullEmpName(authMe?.direct_manager) : ""}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >المسؤول المباشر: </span>
                </div>
            </div>

            </div>


        </div>
    </div>

   
   
    {/*for the navivagtion buttons */}
    <NavButtonsComp/>
      
      </>

      }







    </div>
      )
      :
      (
        <></>
      )
     } 
    




    </div>
  )
}

export default MainPage