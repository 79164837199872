import React from 'react'
import { useSelector } from 'react-redux'
import ChangePassword from '../../components/ChangePassword/ChangePassword';

import './changepass.scss';

function ChangePass() {

  const authMe = useSelector(state => state.authme.data);

  return (
    <div className='changePass'>

     <div className='changePass_container'>
             <ChangePassword empInfo={authMe} url={`https://emp.almatin.com/api/V1/auth/password/change`} methodType="post" isChangerIsUser={true}/>
     </div>

    </div>
  )
}

export default ChangePass