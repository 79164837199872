// src/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
 userData:null,
};

const changeUserDataSlice = createSlice({
  name: 'changeUserData',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.userData = action.payload;
    },
    clearUserData(state) {
      state.userData  = null;
    }
  }
});

export const { setUserData, clearUserData } = changeUserDataSlice.actions;
export default changeUserDataSlice.reducer;
