import { useEffect } from "react";
import { isMobile, browserName, browserVersion } from "react-device-detect";

const UnsupportedBrowserAlert = () => {
  
  useEffect(() => {
    const isOldBrowser = () => {
      const oldBrowsers = [
        { name: "Chrome", version: 30 },
        { name: "Android Browser", version: 4.4 },
        { name: "Safari", version: 9 },
      ];

      const currentBrowser = oldBrowsers.find(
        (browser) =>
          browserName === browser.name &&
          parseFloat(browserVersion) <= browser.version
      );

      return !!currentBrowser;
    };

    if (isMobile && isOldBrowser()) {
        alert(
            "متصفح غير مدعوم\n\nهاتفك أو متصفحك قديم جدًا لاستخدام هذا الموقع. يرجى تحديث متصفحك أو الانتقال إلى جهاز حديث للحصول على أفضل تجربة."
          );
    }
  }, []);

  return null; // No visual component, just the alert
};

export default UnsupportedBrowserAlert;
