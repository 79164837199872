import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ShowManagerVacationsComp from './ShowManagerVacationsComp/ShowManagerVacationsComp';
import {errorHandeling} from "../../errorHandeling";
import { useSelector } from 'react-redux';

function ShowManagerVacationsMainPage() {

    const token = useSelector(state => state.user.token)

    const authMe = useSelector(state => state.authme.data);

    const [vacationsArr,setVacationsArr] = useState([]);

    const [loading,setLoading] = useState(false);

    const [error,setError] = useState(null);

    useEffect(()=>{
              fetchVacations();
             },[])


    const fetchVacations = async () => {

        if(authMe?.is_manager){
        try {
          setLoading(true);
          const response = await axios.get(
          `https://emp.almatin.com/api/V1/vacations/waiting/get?page=${1}`,
         
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Response:', response.data.data.data);
          setVacationsArr(response.data.data.data);
          setLoading(false);
        } catch (error) {
          console.error('Error:', error);
          setLoading(false);
          setError(errorHandeling(error))
      
        }
      }
      
      };
      

  return (
    <div>
        {
         loading ?
         (<></>)
         :
         (   
        (authMe?.is_manager ) &&
             (
              <>
              {
                (error) &&
                (<span style={{color:"red"}}>{error}</span>) 
              }
              {
             vacationsArr.length > 0 ?
            (
            <ShowManagerVacationsComp fetchVacations={fetchVacations} vacationsArr={vacationsArr.length > 3 ? vacationsArr.slice(0,3) : vacationsArr} 
              showMore = {vacationsArr.length > 3 ? true : false}
            />)
             :
            (<></>)
            }
            </>  
             )
   
        ) 
        }

    </div>
  )
}

export default ShowManagerVacationsMainPage