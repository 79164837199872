import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
//to support old versions of browsers
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
// import 'flex-gap-polyfill';

import 'whatwg-fetch';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './store/store';
import UnsupportedBrowserAlert from './libs/UnsupportedBrowserMessage ';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
   
    <UnsupportedBrowserAlert/>

      <App />

  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
