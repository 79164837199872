import React, { useState } from 'react'
import './changepassword.scss'
import { BsPersonCheck } from "react-icons/bs";
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'
import { Link, useNavigate } from 'react-router-dom';
import {errorHandeling} from '../../errorHandeling'
import { clearAuthMe, fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';
import { clearUser } from '../../store/UserSlice/userSlice';

function ChangePassword({empInfo,url,methodType,isChangerIsUser}) {

  const token = useSelector(state => state.user.token)

  const authMe = useSelector((state) => state.authme.data);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  

  const [newPasswordField , SetnewPasswordField] = useState("")
  const [newPasswordAuthField , SetnewPasswordAuthField] = useState("")


  const [loading,setLoading] = useState(false);
  const [error, setError] = useState('');
  const [changePassSuccessfully,setChangePassSuccessfully] = useState(false)

  let  NewPasswordFunc = (e) => {
    SetnewPasswordField(e.target.value);
  }

  let  NewPasswordAuthFunc = (e) => {
    SetnewPasswordAuthField(e.target.value);
  }



  const handleSubmit = async (e) => {
    e.preventDefault();

       // Reset error message
        setError('');

        // Validation check
        if (newPasswordField.trim() === '' || newPasswordAuthField.trim() === '') {
          setError('الحقول لا يجب أن تكون فارغة');
          return;
        }
    
        if (newPasswordField !== newPasswordAuthField) {
          setError('حقل كلمة المرور و حقل تأكيد كلمة المرور يجب أن يكونا متطابقان');
          return;
        }

        if ( newPasswordField.length < 5 ) {
          setError('كلمة المرور يجب أن تكون بطول 5 أحرف أو أكثر');
          return;
        }


        const data = {
          password:newPasswordField,
          password_confirmation:newPasswordAuthField
        };
  
    try {
      setLoading(true)
      const response = await axios(
        {
          method: methodType, 
          url: url,
          data: data,
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
        
        }
      );

      SetnewPasswordField("")
      SetnewPasswordAuthField("")
      if(!isChangerIsUser){
        setChangePassSuccessfully(true)
        setLoading(false);
      }
      else{
            // Fetch `authMe` and navigate to `/emp`
            try {
              await dispatch(fetchAuthMe()); // Wait for fetchAuthMe to complete
              setLoading(false);
              navigate("/emp"); // Then navigate
          } catch (fetchError) {
              console.error("Error fetching authMe:", fetchError);
              setError("هناك خطأ ما يرجى المحاولة لاحقا");
          }
      }
      
    } catch (error) {
      let err = error.response?.data?.errors?.password || errorHandeling(error)
     
      setError(err)
      setLoading(false)
      setChangePassSuccessfully(false)
      console.error('Error:', error);

    }

  };
  
  
  const handelLogOut = (e)=>{
    e.preventDefault();
    // Clear local storage
    dispatch(clearAuthMe())
    dispatch(clearUser());

    const serializedState = localStorage.getItem('state');

    if (serializedState !== null) {
       localStorage.removeItem('state');

     }
    
    // Dispatch logout action to clear Redux state


    // Navigate to the login page or another page
    navigate('/login');
}


  return (
    <div className="EmpsMangment_search_result_table_component_change_pass">
    
    <div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo?.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>

    {
        (isChangerIsUser) &&
         (
          <div className='note_text'>
          {// if the the changer for the password is the user and that is the first login to the site then
            
            (authMe?.change_password) &&
            (
              <span dir='rtl'><span className='note_star'>*</span>بجب تغيير كلمة المرور عند تسجيل الدخول لأول مرة</span>
            )
          }
         
         <span dir='rtl'><span className='note_star'>*</span>يمكنك أيضا <span  className='note_logout_text' onClick={(e)=>{handelLogOut(e)}} dir='rtl'>تسجيل الخروج</span></span>
         </div>
         )
    }


    {
      changePassSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم تغيير كلمة السر بنجاح</span>
          
   
      </div>

      :
      <></>
    }

    <form onSubmit={handleSubmit}>

   <div className="EmpsMangment_search_result_table_component_items">

   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تأكيد كلمة المرور الجديدة</label>
      <input type="text" dir="rtl" value={newPasswordAuthField} onChange={NewPasswordAuthFunc} />
      </div>

    <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">كلمة المرور الجديدة</label>
      <input type="text" dir="rtl" value={newPasswordField} onChange={NewPasswordFunc} />
      </div>


      </div>

      {
        error &&
        <span dir='rtl' className='error_in_changePassword'>{error}</span>
      }


      <div className='btns_cover'>

      <button  className='change_password_btn' dir="rtl" type='submit' disabled={loading}>
        {
          loading ?
          <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
          </>
          :
          <>حفظ</>
        }
      </button>



</div>
      
      </form>

    </div>
  )
}

export default ChangePassword