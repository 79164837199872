// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';
import userSlice from './UserSlice/userSlice';
import authmeSlice from './AuthmeSlice/authmeSlice';
import createUserReducer from './CreateUserSlice/createUserSlice';
import changeUserDataReducer from './ChangeUserDataSlice/changeUserDataSlice';


const preloadedState = loadState();



const store = configureStore({
  reducer: {
    user: userSlice,
    createUser: createUserReducer,
    changeUserData: changeUserDataReducer,
    authme : authmeSlice
  },
  
  preloadedState

});


store.subscribe(() => {
    if(store.getState().user.theRemberMe)
       saveState(store.getState());
  });



export default store;
